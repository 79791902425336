import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

const VisualMode = ({ year, category }) => {
  const state = useSelector((state) => state.subCategories);

  const layoutSizeFromAward = (size) => {
    switch (size) {
      case "BEST":
        return "col-md-5";
      case "GOLD":
        return "col-md-5";
      case "SILVER":
        return "col-md-4";
      case "BRONZE":
        return "col-md-4";
      case "FINALIST":
        return "col-md-3";
      case "HONOURABLE AWARD":
          return "col-md-5";
      default:
        return "col-md-3";
    }
  };

  return (
    <div className="container">
      <div className="visual-mode">
        <div className="row">
          {!state.loading &&
            state.data?.subcategories &&
            state.data?.subcategories.map((o, i) => (
              <div key={i}>
                <div className="col-12 mb-5">
                  <div className="space-left">
                    <h3>
                      <span className="code">{o.code}</span>
                      {o.title}
                    </h3>
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          {o.entry.length > 0 &&
                            o.entry.map((e, j) => (
                              <div
                                className={classNames(
                                  layoutSizeFromAward(e.award),
                                  "mb-4"
                                )}
                                key={`${i}_${j}`}
                              >
                                <div className="bg">
                                  <a
                                    href={`/winner-archives/${year}/${category}/${o.id}/${e.id}`}
                                    className="entry-link scale-in-right"
                                    style={{
                                      backgroundImage: `url(${e.image[0]})`,
                                    }}
                                  >
                                    <span className="caption">
                                      <span className="title">{e.title}</span>
                                      <span className="desc">
                                        <strong>AWARD : {e.award}</strong>
                                        <br />
                                        {e.brand}
                                        <br />
                                        {e.agency}
                                      </span>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            ))}
                          {o.entry.length === 0 && (
                            <div className="col">
                              <div className="no-entry">
                                NO AWARD <div className="line"></div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default VisualMode;

import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import img from "../images/cea-logo.png";

export default function Home({ location }) {
  const [helmet, setHelmet] = React.useState({});
  const [yearSelected, setYearSelected] = React.useState(window.DEFAULT_YEAR);
  const [isOpenDropdown, setIsOpenDropDown] = React.useState(false);
  const state = useSelector((state) => state.filter);

  React.useEffect(() => {
    setHelmet({
      title: "Home - " + window.DEFAULT_TITLE,
    });
  }, [location]);

  const handleChangeYear = (year) => {
    setYearSelected(year);
    setIsOpenDropDown(false);
  };

  return (
    <Layout title={helmet.title} disableFooter>
      <div className="home">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-lg-6">
              <h1>
                ADMAN
                <span>AWARDS</span>
              </h1>
              <p>
                Adman Archive เว็บไซต์นี้
                สร้างขึ้นเพื่อเป็นศูนย์รวมงานโฆษณาที่ได้รางวัลในเวที Adman
                Awards ตั้งแต่ปี 2017
                เป็นต้นมาเพื่อให้เป็นข้อมูลที่เป็นประโยชน์กับวงการความคิดสร้างสรรค์งานโฆษณาสืบเนื่องต่อไปสามารถเลือกดูงานที่ได้รางวัลจาก
                Adman ในแต่ละหมวดหมู่ของแต่ละปีได้โดยเลือกปีที่ต้องการ
              </p>
              <div className="actions">
              <div className="select-year">
              <button
                className={classNames("toggle", isOpenDropdown && "open")}
                onClick={() => setIsOpenDropDown(!isOpenDropdown)}
              >
               Award {yearSelected === 2024 ? "ADPEOPLE" : "Adman"}  {yearSelected}
              </button>
              {isOpenDropdown && (
                <div className="options">
                  {!state.loading &&
                    state.data.years.map((y, i) => (
                      <button
                        key={i}
                        onClick={() => handleChangeYear(y.value)}
                      >
                        Award {y.name === 2024 ? "ADPEOPLE" : "Adman"} {y.name}
                      </button>
                    ))}
                </div>
              )}
            </div>
                <Link to={`/winner-archives/${yearSelected}`} className="go-to">
                  <span>GO</span>
                </Link>
                <div className="arrow"></div>
              </div>
            </div>
          </div>
          <div className="support">
            ด้วยความสนับสนุนจาก สำนักงานส่งเสริมเศรษฐกิจสร้างสรรค์
            (องค์การมหาชน)
            <div className="img">
              <img
                src={img}
                alt="สำนักงานส่งเสริมเศรษฐกิจสร้างสรรค์
                (องค์การมหาชน)"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
